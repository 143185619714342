import asyncEntityHandlers from '@anm/helpers/redux/asyncEntityHandlers';
import reduceFactory from '@anm/helpers/redux/reduceFactory';
import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import { teamActions, TeamActions, TeamInvitationState } from '../';
import baseState from '../helpers/baseState';

const initialInvitationState: TeamInvitationState = {
  data: [],
  invite: baseState,
  signupInvite: baseState,
  accept: baseState,
  reject: baseState,
  resend: baseState,
  delete: baseState,
  fetchList: baseState,
  fetchInviteInfo: { data: null, isPending: false, error: null, isError: false }
};

const InvitationReducer: Reducer<TeamInvitationState, TeamActions> = (state = initialInvitationState, action) => {
  switch (action.type) {
    case getType(teamActions.getInvitationsListAsync.success): {
      return {
        ...state,
        data: action.payload,
        fetchList: baseState
      };
    }
    default:
      return reduceFactory(initialInvitationState, {
        ...asyncEntityHandlers<TeamInvitationState>(teamActions.inviteMemberAsync, 'invite'),
        ...asyncEntityHandlers<TeamInvitationState>(teamActions.signupInviteAsync, 'signupInvite'),
        ...asyncEntityHandlers<TeamInvitationState>(teamActions.acceptInviteAsync, 'accept'),
        ...asyncEntityHandlers<TeamInvitationState>(teamActions.rejectInviteAsync, 'reject'),
        ...asyncEntityHandlers<TeamInvitationState>(teamActions.resendInviteAsync, 'resend'),
        ...asyncEntityHandlers<TeamInvitationState>(teamActions.deleteInviteAsync, 'delete'),
        ...asyncEntityHandlers<TeamInvitationState>(teamActions.getInvitationsListAsync, 'fetchList'),
        ...asyncEntityHandlers<TeamInvitationState>(teamActions.getInvitationAsync, 'fetchInviteInfo')
      })(state, action);
  }
};

export default InvitationReducer;
