import { Team } from '@anm/api/modules/team';
import asyncEntityHandlers from '@anm/helpers/redux/asyncEntityHandlers';
import reduceFactory from '@anm/helpers/redux/reduceFactory';
import { getType } from '@anm/helpers/saga/typesafe-actions';
import { isEmpty, omitBy } from 'lodash/fp';
import { Reducer } from 'redux';

import { teamActions, TeamActions, TeamInfoState } from '../';
import baseState from '../helpers/baseState';

const initialInfoState: TeamInfoState = {
  data: null,
  secureUser: null,
  fetchTeam: baseState,
  updateTeam: baseState,
  deleteTeam: baseState,
  deleteMember: baseState,
  updateMemberRole: baseState
};

const infoReducer: Reducer<TeamInfoState, TeamActions> = (state = initialInfoState, action) => {
  switch (action.type) {
    case getType(teamActions.fetchTeamAsync.success): {
      return {
        ...state,
        data: action.payload,
        fetchTeam: initialInfoState.fetchTeam
      };
    }
    case getType(teamActions.updateTeamAsync.success): {
      return {
        ...state,
        data: { ...state.data, ...(omitBy<Team>(isEmpty)(action.payload) as Team) },
        updateTeam: initialInfoState.updateTeam
      };
    }
    case getType(teamActions.deleteTeamAsync.success): {
      return initialInfoState;
    }
    default:
      return reduceFactory(initialInfoState, {
        ...asyncEntityHandlers<TeamInfoState>(teamActions.fetchTeamAsync, 'fetchTeam'),
        ...asyncEntityHandlers<TeamInfoState>(teamActions.updateTeamAsync, 'updateTeam'),
        ...asyncEntityHandlers<TeamInfoState>(teamActions.deleteTeamAsync, 'deleteTeam'),
        ...asyncEntityHandlers<TeamInfoState>(teamActions.deleteMemberAsync, 'deleteMember'),
        ...asyncEntityHandlers<TeamInfoState>(teamActions.updateMemberRoleAsync, 'updateMemberRole')
      })(state, action);
  }
};

export default infoReducer;
