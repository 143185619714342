import selectProps from '@anm/helpers/store/selectProps';
import { pick } from 'lodash/fp';
import { ApiError } from 'packages/HostingPlayer/types';
import { createSelector } from 'reselect';

import { ApplicationState } from '../../../store/types';

const getError = <T extends { error?: ApiError | null; isError: boolean }>(state: T) =>
  pick<typeof state, keyof typeof state>(['error', 'isError'])(state);

export const selectTeamInfoState = ({ team }: ApplicationState) => team?.info;

export const selectSecureUser = createSelector(selectTeamInfoState, ({ secureUser }) => secureUser);

export const selectTeamAvatar = createSelector(selectTeamInfoState, ({ data }) => data?.logo);
export const selectTeamDisplayName = createSelector(selectTeamInfoState, ({ data }) => data?.displayName);
export const selectTeamId = createSelector(selectTeamInfoState, ({ data }) => data?.id);
export const selectTeamMembers = createSelector(selectTeamInfoState, ({ data }) => data?.members);
export const selectTeamMemberByUsername = createSelector(
  selectTeamMembers,
  selectProps<string | null>(),
  (members, username) => members?.find(member => member.username === username)
);
export const selectTeamFetchPending = createSelector(selectTeamInfoState, ({ fetchTeam }) => fetchTeam?.isPending);

export const selectTeamUpdatePending = createSelector(selectTeamInfoState, ({ updateTeam }) => updateTeam.isPending);
export const selectTeamDeletePending = createSelector(selectTeamInfoState, ({ deleteTeam }) => deleteTeam.isPending);
export const selectDeleteMemberPending = createSelector(
  selectTeamInfoState,
  ({ deleteMember }) => deleteMember.isPending
);

export const selectUpdateRolePending = createSelector(
  selectTeamInfoState,
  ({ updateMemberRole }) => updateMemberRole.isPending
);

export const selectTeamInvitationState = ({ team }: ApplicationState) => team.invitations;

export const selectInvitation = createSelector(
  selectTeamInvitationState,
  ({ fetchInviteInfo }) => fetchInviteInfo.data
);
export const selectInvitationsList = createSelector(selectTeamInvitationState, ({ data }) => data);

export const selectGetInvitationError = createSelector(selectTeamInvitationState, ({ fetchInviteInfo }) =>
  getError(fetchInviteInfo)
);
export const selectMemberInviteError = createSelector(selectTeamInvitationState, ({ invite }) => getError(invite));
export const selectSignupInviteError = createSelector(selectTeamInvitationState, ({ signupInvite }) =>
  getError(signupInvite)
);
export const selectAcceptInviteError = createSelector(selectTeamInvitationState, ({ accept }) => getError(accept));
export const selectRejectInviteError = createSelector(selectTeamInvitationState, ({ reject }) => getError(reject));
export const selectFetchListError = createSelector(selectTeamInvitationState, ({ fetchList }) => getError(fetchList));
export const selectDeleteInviteError = createSelector(selectTeamInvitationState, state => getError(state.delete));
export const selectTeamFetchError = createSelector(selectTeamInfoState, ({ fetchTeam }) => getError(fetchTeam));

export const selectGetInvitationPending = createSelector(
  selectTeamInvitationState,
  ({ fetchInviteInfo }) => fetchInviteInfo.isPending
);
export const selectMemberInvitePending = createSelector(selectTeamInvitationState, ({ invite }) => invite.isPending);
export const selectSignupInvitePending = createSelector(
  selectTeamInvitationState,
  ({ signupInvite }) => signupInvite.isPending
);
export const selectAcceptInvitePending = createSelector(selectTeamInvitationState, ({ accept }) => accept.isPending);
export const selectRejectInvitePending = createSelector(selectTeamInvitationState, ({ reject }) => reject.isPending);
export const selectFetchListPending = createSelector(selectTeamInvitationState, ({ fetchList }) => fetchList.isPending);
export const selectDeleteInvitePending = createSelector(selectTeamInvitationState, props => props.delete.isPending);
