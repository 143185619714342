import api from '@anm/api';
import {
  DeleteMemberParams,
  InvitationData,
  InviteHashParams,
  RoleUpdateParams,
  TeamUpdateData
} from '@anm/api/modules/team';
import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import asyncEntity from '@anm/helpers/redux/asyncEntity';
import { call, put, select, take } from '@anm/helpers/saga/effects';
import { takeType } from '@anm/helpers/saga/typesafe-actions';
import { appNotificationActions } from '@anm/store/modules/appNotifications';

import getWebsiteUrl from '../../../helpers/getWebsiteUrl';
import routes from '../../../routes';
import { appMetaSelectors } from '../appMeta';
import { authActions } from '../auth';
import { userActions, userSelectors } from '../user';

import { teamActions, teamSelectors } from '.';
import { SignupInviteProps } from './actions';

const { Router } = routes;

const fetchTeamAsync = asyncEntity(teamActions.fetchTeamAsync, () => api().team.getTeam());
const updateTeamAsync = asyncEntity(teamActions.updateTeamAsync, (data: TeamUpdateData) => api().team.updateTeam(data));
const deleteTeamAsync = asyncEntity(teamActions.deleteTeamAsync, () => api().team.deleteTeam());

const inviteMemberAsync = asyncEntity(teamActions.inviteMemberAsync, (data: InvitationData) =>
  api().team.inviteMember(data)
);
const acceptInviteAsync = asyncEntity(teamActions.acceptInviteAsync, (hash: string) =>
  api().team.acceptInvite({ hash })
);

const rejectInviteAsync = asyncEntity(teamActions.rejectInviteAsync, (hash: string) =>
  api().team.rejectInvite({ hash })
);
const resendInviteAsync = asyncEntity(teamActions.resendInviteAsync, (hash: string) =>
  api().team.resendInvite({ hash })
);

const getInvitationAsync = asyncEntity(teamActions.getInvitationAsync, (hash: string) =>
  api().team.getInvitation({ hash })
);
const getInvitationsListAsync = asyncEntity(teamActions.getInvitationsListAsync, () => api().team.getInvitationsList());
const deleteMemberAsync = asyncEntity(teamActions.deleteMemberAsync, (params: DeleteMemberParams) =>
  api().team.deleteMember(params)
);
const deleteInviteAsync = asyncEntity(teamActions.deleteInviteAsync, (params: InviteHashParams) =>
  api().team.deleteInvitation(params)
);
const updateMemberRoleAsync = asyncEntity(teamActions.updateMemberRoleAsync, (params: RoleUpdateParams) =>
  api().team.updateMemberRole(params)
);

function* watchTeamFetch() {
  while (true) {
    yield* take(takeType(teamActions.fetchTeam));
    yield* call(fetchTeamAsync);
  }
}

function* watchTeamUpdate() {
  while (true) {
    const { payload } = yield* take(takeType(teamActions.updateTeam));
    yield* call(updateTeamAsync, payload);
  }
}

function* watchTeamDelete() {
  while (true) {
    yield* take(takeType(teamActions.deleteTeam));
    yield* call(deleteTeamAsync);
  }
}

function* watchTeamDeleteSuccess() {
  while (true) {
    yield* take(takeType(teamActions.deleteTeamAsync.success));
    yield* put(userActions.getUser());
  }
}

function* watchMemberDelete() {
  while (true) {
    const { payload } = yield* take(takeType(teamActions.deleteMember));
    yield* call(deleteMemberAsync, payload);
  }
}
function* watchInviteDelete() {
  while (true) {
    const { payload } = yield* take(takeType(teamActions.deleteInvite));
    yield* call(deleteInviteAsync, payload);
  }
}
function* watchMemberRoleUpdate() {
  while (true) {
    const { payload } = yield* take(takeType(teamActions.updateMemberRole));
    yield* call(updateMemberRoleAsync, payload);
  }
}

export function* getMemberInviteCallbackUrl() {
  const product = yield* select(appMetaSelectors.selectAppMetaProduct);

  return `${getWebsiteUrl(product)}team/invite/`;
}
function* watchMemberInvite() {
  while (true) {
    const { payload } = yield* take(takeType(teamActions.inviteMember));
    const callback = yield* getMemberInviteCallbackUrl();

    yield* call(inviteMemberAsync, { callback, ...payload });
  }
}
function* getInvitationsListGuard() {
  const userTeamPermissions = yield* select(userSelectors.selectTeamPermissions);

  if (userTeamPermissions?.canInvite) {
    yield* call(getInvitationsListAsync);
  }
}

function* watchInvitationsChanges() {
  while (true) {
    yield* take([
      takeType(teamActions.getInvitationsList),
      takeType(teamActions.inviteMemberAsync.success),
      takeType(teamActions.deleteInviteAsync.success)
    ]);

    yield* call(getInvitationsListGuard);
  }
}
function* watchMemberChanges() {
  while (true) {
    yield* take([takeType(teamActions.deleteMemberAsync.success), takeType(teamActions.updateMemberRoleAsync.success)]);

    yield* put(teamActions.fetchTeam());
  }
}

function* watchUpdateMemberRoleSuccess() {
  while (true) {
    yield* take(takeType(teamActions.updateMemberRoleAsync.success));

    yield* call(getInvitationsListGuard);
    yield* put(teamActions.fetchTeam());
  }
}

function* signupInviteAsync(data: SignupInviteProps) {
  const inviteData = yield* select(teamSelectors.selectInvitation);
  if (!inviteData) return;

  const { name, password } = data;
  const { userEmail } = inviteData;

  try {
    yield* put(teamActions.signupInviteAsync.request(data));

    if (hasAuthAccess()) {
      yield* put(authActions.logout());
      yield* take(takeType(authActions.logoutAsync.success));
    }

    yield* put(
      authActions.signup({
        type: 'EMAIL',
        entry: 'LANDING',
        product: 'WAVE',
        displayName: name,
        email: userEmail,
        password
      })
    );
    yield* take(takeType(authActions.authFinished));

    yield* put(teamActions.acceptInvite());
    yield* take(takeType(teamActions.acceptInviteAsync.success));

    yield* put(teamActions.signupInviteAsync.success());
  } catch (error) {
    yield* put(teamActions.signupInviteAsync.failure(error));
  }

  const {
    payload: { message }
  } = yield* take([
    takeType(authActions.logoutAsync.failure),
    takeType(authActions.authAsync.failure),
    takeType(teamActions.acceptInviteAsync.failure)
  ]);

  if (message) {
    yield* put(teamActions.signupInviteAsync.failure(message));
  }
}

function* watchSignupInvite() {
  while (true) {
    const { payload } = yield* take(takeType(teamActions.signupInvite));

    yield* call(signupInviteAsync, payload);
  }
}
function* watchGetInvitation() {
  while (true) {
    yield* take(takeType(teamActions.getInvitation));
    const { hash } = Router.query;
    if (hash) {
      yield* call(getInvitationAsync, hash as string);
    }
  }
}
function* watchAcceptInvite() {
  while (true) {
    yield* take(takeType(teamActions.acceptInvite));
    const { hash } = Router.query;
    if (hash) {
      yield* call(acceptInviteAsync, hash as string);
    }
  }
}
function* watchRejectInvite() {
  while (true) {
    yield* take(takeType(teamActions.rejectInvite));
    const { hash } = Router.query;

    if (hash) {
      yield* call(rejectInviteAsync, hash as string);
    }
  }
}
function* watchResendInvite() {
  while (true) {
    const { payload } = yield* take(takeType(teamActions.resendInvite));

    yield* call(resendInviteAsync, payload);
  }
}
function* watchResendInviteSuccess() {
  while (true) {
    yield* take(takeType(teamActions.resendInviteAsync.success));

    yield* put(
      appNotificationActions.notify({
        type: 'success',
        timeout: 5000,
        title: 'Invitation sent'
      })
    );
  }
}
function* watchFailures() {
  while (true) {
    const {
      payload: { code, message }
    } = yield* take([
      takeType(teamActions.fetchTeamAsync.failure),
      takeType(teamActions.resendInviteAsync.failure),
      takeType(teamActions.inviteMemberAsync.failure),
      takeType(teamActions.acceptInviteAsync.failure),
      takeType(teamActions.rejectInviteAsync.failure),
      takeType(teamActions.deleteInviteAsync.failure),
      takeType(teamActions.deleteMemberAsync.failure),
      takeType(teamActions.updateMemberRoleAsync.failure)
    ]);

    if (code && message) {
      yield* put(
        appNotificationActions.notify({
          type: 'error',
          timeout: 15000,
          title: message
        })
      );
    }
  }
}
function* watchAcceptInviteSuccess() {
  while (true) {
    yield* take(takeType(teamActions.acceptInviteAsync.success));

    yield* put(userActions.getUser());
  }
}

const teamSagaWatchers = () => [
  call(watchFailures),
  call(watchTeamFetch),
  call(watchTeamUpdate),
  call(watchTeamDelete),
  call(watchMemberDelete),
  call(watchMemberInvite),
  call(watchAcceptInvite),
  call(watchAcceptInviteSuccess),
  call(watchSignupInvite),
  call(watchRejectInvite),
  call(watchResendInvite),
  call(watchResendInviteSuccess),
  call(watchInviteDelete),
  call(watchGetInvitation),
  call(watchMemberChanges),
  call(watchInvitationsChanges),
  call(watchMemberRoleUpdate),
  call(watchUpdateMemberRoleSuccess),
  call(watchTeamDeleteSuccess)
];

export default teamSagaWatchers;
