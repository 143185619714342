import { ApiError } from '@anm/api';
import { DeleteMemberParams, RoleUpdateParams, Team, TeamInvitation, TeamUpdateData } from '@anm/api/modules/team';
import { InvitationData } from '@anm/api/modules/team';
import { InviteHashParams } from '@anm/api/modules/team';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { InviteMemberProps } from './types';

export const fetchTeam = createAction('wave.video/team/FETCH_TEAM')();
export const fetchTeamAsync = createAsyncAction(
  'wave.video/team/FETCH_TEAM_REQUEST',
  'wave.video/team/FETCH_TEAM_SUCCESS',
  'wave.video/team/FETCH_TEAM_ERROR'
)<void, Team, ApiError>();

export const updateTeam = createAction('wave.video/team/UPDATE_TEAM')<TeamUpdateData>();
export const updateTeamAsync = createAsyncAction(
  'wave.video/team/UPDATE_TEAM_REQUEST',
  'wave.video/team/UPDATE_TEAM_SUCCESS',
  'wave.video/team/UPDATE_TEAM_ERROR'
)<TeamUpdateData, Team, ApiError>();

export const deleteTeam = createAction('wave.video/team/DELETE_TEAM')();
export const deleteTeamAsync = createAsyncAction(
  'wave.video/team/DELETE_TEAM_REQUEST',
  'wave.video/team/DELETE_TEAM_SUCCESS',
  'wave.video/team/DELETE_TEAM_ERROR'
)<void, void, ApiError>();

export const inviteMember = createAction('wave.video/team/INVITE_MEMBER')<InviteMemberProps>();
export const inviteMemberAsync = createAsyncAction(
  'wave.video/team/INVITE_MEMBER_REQUEST',
  'wave.video/team/INVITE_MEMBER_SUCCESS',
  'wave.video/team/INVITE_MEMBER_ERROR'
)<InvitationData, void, ApiError>();

export const acceptInvite = createAction('wave.video/team/ACCEPT_INVITE')();
export const acceptInviteAsync = createAsyncAction(
  'wave.video/team/ACCEPT_INVITE_REQUEST',
  'wave.video/team/ACCEPT_INVITE_SUCCESS',
  'wave.video/team/ACCEPT_INVITE_ERROR'
)<string, void, ApiError>();

export type SignupInviteProps = { name: string; password: string };
export const signupInvite = createAction('wave.video/team/SIGNUP_INVITE')<SignupInviteProps>();
export const signupInviteAsync = createAsyncAction(
  'wave.video/team/SIGNUP_INVITE_REQUEST',
  'wave.video/team/SIGNUP_INVITE_SUCCESS',
  'wave.video/team/SIGNUP_INVITE_ERROR'
)<SignupInviteProps, void, ApiError>();

export const rejectInvite = createAction('wave.video/team/REJECT_INVITE')();
export const rejectInviteAsync = createAsyncAction(
  'wave.video/team/REJECT_INVITE_REQUEST',
  'wave.video/team/REJECT_INVITE_SUCCESS',
  'wave.video/team/REJECT_INVITE_ERROR'
)<string, void, ApiError>();

export const deleteInvite = createAction('wave.video/team/DELETE_INVITE')<InviteHashParams>();
export const deleteInviteAsync = createAsyncAction(
  'wave.video/team/DELETE_INVITE_REQUEST',
  'wave.video/team/DELETE_INVITE_SUCCESS',
  'wave.video/team/DELETE_INVITE_ERROR'
)<void, void, ApiError>();

export const resendInvite = createAction('wave.video/team/RESEND_INVITE')<string>();
export const resendInviteAsync = createAsyncAction(
  'wave.video/team/RESEND_INVITE_REQUEST',
  'wave.video/team/RESEND_INVITE_SUCCESS',
  'wave.video/team/RESEND_INVITE_ERROR'
)<InviteHashParams, void, ApiError>();

export const getInvitationsList = createAction('wave.video/team/GET_INVITATIONS')();
export const getInvitationsListAsync = createAsyncAction(
  'wave.video/team/GET_INVITATIONS_REQUEST',
  'wave.video/team/GET_INVITATIONS_SUCCESS',
  'wave.video/team/GET_INVITATIONS_ERROR'
)<void, TeamInvitation[], ApiError>();

export const getInvitation = createAction('wave.video/team/GET_INVITATION')();
export const getInvitationAsync = createAsyncAction(
  'wave.video/team/GET_INVITATION_REQUEST',
  'wave.video/team/GET_INVITATION_SUCCESS',
  'wave.video/team/GET_INVITATION_ERROR'
)<InviteHashParams, TeamInvitation, ApiError>();

export const updateMemberRole = createAction('wave.video/team/UPDATE_MEMBER_ROLE')<RoleUpdateParams>();
export const updateMemberRoleAsync = createAsyncAction(
  'wave.video/team/UPDATE_MEMBER_ROLE_REQUEST',
  'wave.video/team/UPDATE_MEMBER_ROLE_SUCCESS',
  'wave.video/team/UPDATE_MEMBER_ROLE_ERROR'
)<RoleUpdateParams, void, ApiError>();

export const deleteMember = createAction('wave.video/team/DELETE_MEMBER')<DeleteMemberParams>();
export const deleteMemberAsync = createAsyncAction(
  'wave.video/team/DELETE_MEMBER_REQUEST',
  'wave.video/team/DELETE_MEMBER_SUCCESS',
  'wave.video/team/DELETE_MEMBER_ERROR'
)<DeleteMemberParams, void, ApiError>();
